import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Chase V.| GIS Analyst & Developer', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Chase VanSchoonhoven|GIS Analyst & Developer|mapping.cool', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: 'Hi, there! My name is',
  name: 'Chase',
  subtitle: "I'm a Geospatial Analyst and Developer.",
  cta: 'About Me',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne: 'Hi, I’m Chase',
  paragraphTwo:
    "I'm a geospatial analyst and developer, who originally hails from Fresno, CA but currently lives in Orange, CA. I recently graduated from the University of Southern California with a Master's in Geographic Information Science and Technology, and before that I got my Bachelor's degree from the University of California Santa Barbara in Anthropology and Sociology. I'm currently a Geospatial Intelligence Imagery Analyst for the U.S. Army Reserves and I work for Bedford Reinforced Plastics as a Project Estimator. I enjoy telling stories and relaying information through visualizations, dashboards, and front-end applications.",
  paragraphThree:
    "When I am not working, my wife and I spend much of our time chasing our toddler son around, he keeps us busy but it's worth it. In my spare time, I enjoy hiking, backpacking, rock climbing, playing video games, along with the many other hobbies I seem to cycle between.",
  resume: 'https://drive.google.com/file/d/11LX2698L9jyhiwSp_-RqV1n8dSNkzpfa/view?usp=sharing', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'california-glaciers.jpg',
    title: 'Web-Based Relational Spatiotemporal Geodatabase of Glaciers in California ',
    info:
      'The objective of this thesis project was to create a web-based relational geodatabase and web map to track spatiotemporal information of glaciers in California. This database integrates existing inventories, as well as aims to allow for the incorporation of new data collected with more advanced technology and improved resolutions.',
    skills:
      'Skills: Data Modeling, Data Analysis, Visualization, Data Processing, Database Design, Database Implementation, Database Management, Web Mapping',
    tools: 'Tools: ArcGIS Pro, ArcGIS Online, pgAdmin, pgModeler, Excel, AWS',
    languages: 'Languages: PostgreSQL',
    url:
      'https://uscssi.maps.arcgis.com/apps/webappviewer/index.html?id=b5b8211d128a44a7a4d8f40edfffe0b6',
    repo: 'https://github.com/krevee/california_glaciers', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'wolverine.jpg',
    title: 'Wolverine Connectivity Corridor',
    info:
      'This map is a wildlife corridor linkage map for the Wolverine (Gulo Gulo) from its current territory in Washington, Oregon and Idaho to the Sierra Nevada Mountains. The purpose of this map is to help conservation efforts in the United States that aim to repopulate Wolerines in the Sierra Nevada Mountains.',
    skills: 'Skills: Data Modeling, Data Analysis, Visualization, Data Processing',
    tools: 'Tools: ArcGIS Pro, Excel',
    languages: 'Languages: Python',
    url:
      'https://github.com/krevee/GIS-Porfolio/blob/main/Wolverine%20Connectivity%20Corridor/Wolverine%20Connectivity%20Corridor.pdf',
    repo: 'https://github.com/krevee/GIS-Porfolio/tree/main/Wolverine%20Connectivity%20Corridor', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'UAS-Orthomosaic.jpg',
    title: 'UAS Orthomosaic Imagery Applications',
    info:
      'This project aimed to measure the accuracy of orthomosaic imagery acquired from a UAS platform to aide in GIS field research applications. The quality of imagery was tested, and compared to other conventional imagery sources such as satellite and manned aerial platforms. The research was conducted at the Wrigley Marine Institute on Catalina Island.',
    skills: 'Skills: Data Analysis, Data Collection, Data Processing',
    tools: 'Tools: ArcGIS Pro, PIX4D, TerraSync',
    hardware: 'Hardware: Trimble Geoexplorer 6000, Trimble Juno T41/5, DJI Mavic Pro',
    url:
      'https://github.com/krevee/GIS-Porfolio/blob/main/UAS%20Orthomosaic%20Imagery%20Applications/UAS%20Orthomosaic%20Imagery%20Applications%20Poster.pdf',
    repo:
      'https://github.com/krevee/GIS-Porfolio/tree/main/UAS%20Orthomosaic%20Imagery%20Applications', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'fire2.jpeg',
    title: 'Orange County Wildfire Wildland-Urban Risk Assessment',
    info:
      'A model for wildland-urban interfaces that gives local fire safety officials the knowledge necessary to update local fire code that could lower the risk of wildfire at these interfaces.',
    skills: 'Skills: Data Modeling, Data Analysis, Visualization, Data Processing',
    tools: 'Tools: ArcGIS Pro, Excel',
    url:
      'https://github.com/krevee/GIS-Porfolio/blob/main/Orange%20County%20Wildfire%20Wildland-Urban%20Risk%20Assessment/Orange%20County%20Wildfire%20Wildland-Urban%20Risk%20Assessment%20Map.pdf',
    repo:
      'https://github.com/krevee/GIS-Porfolio/tree/main/Orange%20County%20Wildfire%20Wildland-Urban%20Risk%20Assessment', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta:
    'If you liked what you saw, want to have a chat with me about the portfolio, work opportunities, or collaboration',
  btn: "Let's Talk",
  email: 'chase@mapping.cool',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/chasev/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/krevee/',
    },
    // Codepen.io Hidden for now
    // {
    //  id: nanoid(),
    //  name: 'codepen',
    //  url: '',
    // },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/krevee/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
